import React from "react";
import styled from "styled-components";

const IndentedText = styled.div`
  p {
    text-indent: 5%;
  }
`;

export default function SectionDisplay({ key, text }) {
  const lines = text.split("\n");
  return (
    <IndentedText>
      {lines.map(
        (line, index) => line !== "" && <p key={key + String(index)}>{line}</p>
      )}
    </IndentedText>
  );
}
