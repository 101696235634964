import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import AudioPlayer from "../components/AudioPlayer";
import BottomNav from "../components/BottomNav";
import SectionDisplay from "../components/SectionDisplay";
import SectionHeading from "../components/SectionHeading";
import Img from "gatsby-image";
import QuestionDisplay from "../components/QuestionDisplay";

const SectionStyles = styled.div`
  margin: 0;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pic {
    margin-bottom: 10px;
  }
`;

export default function SingleSection({ data, pageContext }) {
  // Get out current section number i.e. permalink
  const current = Number(pageContext.current);
  useEffect(() => {
    // Save current permalink in local storage
    localStorage.setItem("currentPage", current);
  });
  return (
    <>
      <Link to="/contents" className="nav-button">
        कहानियों का सूची
      </Link>
      <SectionStyles>
        <SectionHeading heading={pageContext.heading} />
        <p className="ref">{pageContext.verse}</p>
        <AudioPlayer url={pageContext.url} />
        <SectionDisplay key={data.id} text={pageContext.text} />
        {data.section.image !== null && (
          <Img className="pic" fixed={data.section.image.asset.fixed} />
        )}
        <p className="ref">{data.section.caption}</p>
        <QuestionDisplay />
        <BottomNav current={current} />
      </SectionStyles>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    section: sanitySection(slug: { current: { eq: $slug } }) {
      caption
      id
      image {
        asset {
          fixed(width: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`;
