import React from "react";
import styled from "styled-components";

const QuestionStyles = styled.div`
  text-align: center;
  h3 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
  p:last-child {
    margin-bottom: 10px;
  }
  div {
    text-align: left;
  }
`;

export default function QuestionDisplay() {
  return (
    <QuestionStyles>
      <h3>मनन और चर्चा करने के लिए कुछ सवाल</h3>
      <div>
        <p>1) इस वचन में आपको क्या अच्छा लगा? क्या पसंद नहीं आया?</p>
        <p>2) इस वचन से आप परमात्मा के बारे में क्या सीखते हैं?</p>
        <p>3) इस वचन से आप मनुष्यों के बारे में क्या सीखते हैं?</p>
        <p>4) इस वचन से हमें अपने जीवन में क्या पालन करना चाहिए?</p>
        <p>5) इस वचन को आप किस के साथ बाँटेंगे और कब?</p>
      </div>
    </QuestionStyles>
  );
}
